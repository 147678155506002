<template>
  <div class="changeInfo">
    <div class="content">
      <component
        :is="path"
        ref="editOrCreate"
        style="background: #fff;"
        :init-query="initQuery"
        :dialog-title="dialogTitle"
        :form-type="formType"
        :method-path="methodPath"
        :row="dialogTitle === '编辑' ? selectRow : null"
        go-back-url="/personal/setting"
        @reFresh="reFresh"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var checkBeforPwd = (rule, value, callback) => { // 编辑验证
      if (!value) {
        callback(new Error('请输入原密码'))
      } else if (value === this.itemInfo.nowPwd) {
        callback(new Error('不能与新密码一致'))
      }
      callback()
    }
    var checkNowPwd = (rule, value, callback) => { // 编辑验证
      if (!value) {
        callback(new Error('请输入新密码'))
      } else if (value === this.itemInfo.beforPwd) {
        callback(new Error('不能与原密码一致'))
      } else if (this.itemInfo.agamePwd + '' && value !== this.itemInfo.agamePwd) {
        callback(new Error('两次密码不一致'))
      }
      callback()
    }
    var checkAgamePwd = (rule, value, callback) => { // 编辑验证
      if (!value) {
        callback(new Error('请再一次输入密码'))
      } else if (this.itemInfo.nowPwd + '' && value !== this.itemInfo.nowPwd) {
        callback(new Error('两次密码不一致'))
      }
      callback()
    }
    return {
      initQuery: {},
      dialogTitle: '',
      methodPath: { // 接口必传
        create: 'login/AccountUpdatePwd', // 创建接口
        edit: 'login/AccountUpdatePwd' // 编辑接口
      },
      path: () => import('@/components/tableComponent/editOrCreate.vue'), // 编辑页面,如需要自定义可以引用自己的文件
      formType: { // 编辑按钮内容
        account: {
          label: '账号',
          disabled: true,
          default: this.$store.getters.userInfo.account
        },
        beforPwd: { label: '原密码', validator: checkBeforPwd, isPassword: true },
        nowPwd: { label: '新密码', isPassword: true, validator: checkNowPwd },
        agamePwd: { label: '确认密码', validator: checkAgamePwd, isPassword: true }
      }
    }
  },
  computed: {
    itemInfo() {
      return this.$refs.editOrCreate.itemInfo
    }
  },

  methods: {
    reFresh() {
      this.$go('/personal')
    }
  }
}
</script>

<style lang="scss" scoped>
  .changeInfo{
    background: #f5f9fa;
    flex: 1;
    margin-top: 40px;
    width: 600px;
    .content{
      display: flex;
      flex-direction: column;
      background: #fff;
    }
  }
  ::v-deep{
    .itemInfo{
      padding:unset!important;
    }
  }
</style>
