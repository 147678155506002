<template>
  <div class="UpdatePwd">
    <div class="all changPassword">
      <div>账号：{{ $store.getters.userInfo.account }}</div>
      <div class="click center" @click="$go('/personal/setting/UpdatePwd')">修改密码</div>
    </div>

    <div v-if="!$store.getters.userInfo.wxName && !$store.getters.userInfo.wxHeadImg" class="all toWeixin">
      <div>绑定微信：<span class="type">{{ type }}</span></div>
      <div class="click center" @click="dialogVisible=true">立即绑定</div>
    </div>

    <div v-else class="all toWeixin ">
      <div class="bdWx">
        绑定微信：
        <div class="type">
          <img :src="$store.getters.userInfo.wxHeadImg" alt="">
          <div class="wxName">{{ $store.getters.userInfo.wxName }}</div>
        </div>
      </div>
      <div class="click center" @click="cancelWx">取消绑定</div>
    </div>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
    >
      <div v-loading="loading" class="erWeiMa">
        <div v-if="changeEw" v-show="show" id="qrcode" class="center" />
      </div>
    </el-dialog>
  </div>
</template>

<script>

import QRCode from 'qrcodejs2'
let timeOut; let isStop = false; let msg
export default {
  data() {
    return {
      type: '未绑定',
      dialogVisible: false,
      loading: false,
      show: false,
      times: 0,
      changeEw: true
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.changeEw = false
        this.$nextTick(() => {
          this.changeEw = true
          this.$store.dispatch('personal/AccountGetAuthBindUrl').then(res => {
            this.showQrcode(res.data.path)
            this.authId = res.data.authId
            isStop = false
            this.checkLogin()
          })
        })
      } else if (!val) {
        this.clearTimeOut()
        isStop = true
      }
    }
  },
  destroyed() {
    this.clearTimeOut()
    isStop = true
  },
  created() {
    this.init()
    timeOut = ''
    isStop = false
    msg = ''
  },

  methods: {
    showQrcode(url) {
      this.$nextTick(() => {
        new QRCode('qrcode', {
          text: url, // URL地址
          colorDark: '#000', // 二维码颜色
          colorLight: '#ffffff' // 背景颜色
        })
        this.loading = false
        setTimeout(() => {
          const a = document.querySelector('#qrcode img')
          a.style.width = '400px'
          this.show = true
        }, 50)
      })
    },
    checkLogin() {
      if (isStop) return

      this.$store.dispatch('login/AccountBindWx', { authId: this.authId }).then(res => {
        if (!res.data || !res.data.wxOpenId) {
          if (this.times >= 500) {
            this.$message({
              message: '页面已失效,请刷新页面',
              type: 'error',
              duration: 99999999
            })
          } else {
            this.checkMsgIsShow(res.msg)
            this.times += 1
            timeOut = setTimeout(() => {
              this.checkLogin()
            }, 1000)
          }
        } else {
          this.$message.success('绑定成功')
          this.dialogVisible = false
          this.init()
        }
      })
    },

    checkMsgIsShow(resMsg) { // 判断该错误信息是否提示过
      if (resMsg == '数据记录不存在') return
      if (msg != resMsg) {
        msg = resMsg
        this.$message({
          message: msg || 'Error',
          type: 'error',
          duration: 2 * 1000
        })
      }
    },

    cancelWx() {
      this.$store.dispatch('personal/AccountUnBoundWx').then(res => {
        if (res.code == 0) {
          this.$message.success('解绑成功')
          this.init()
        }
      })
    },
    init() {
      this.$store.dispatch('login/AccountGetAccountInfo', { id: this.$store.getters.userInfo.id }).then(res => {
        if (res.code == 0) {
          this.$store.commit('app/CHANGE_USERINFO', res.data)
        }
      })
      document.addEventListener('visibilitychange', () => {
        if (!document.hidden) { // 处于当前页面
          if (!timeOut) { this.checkLogin() }
        } else {
          this.clearTimeOut()
        }
      })
    },
    clearTimeOut() {
      if (timeOut) {
        clearTimeout(timeOut)
        timeOut = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bdWx{
  display: flex;
  align-items: center;
  .type{
    display: flex;
    align-items: center;
    img{
      width:50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .wxName{
      font-size: 18px;
    }
  }
}
  .UpdatePwd{
    .all{
      margin-top: 27px;
      width: 1056px;
      height: 100px;
      background: #F6F8FA;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left:39px;
      padding-right:627px;
    }
    .changPassword{
      &>:nth-child(1){
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      &>:nth-child(2){
        width: 100px;
        height: 36px;
        border: 1px solid var(--theme);
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--theme);
      }
    }

    .toWeixin{
      &>:nth-child(1){
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        span{
          font-size: 16px;
          color: var(--theme);
        }
      }
      &>:nth-child(2){
        width: 100px;
        height: 36px;
        border: 1px solid var(--theme);
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--theme);
      }
    }
  }
</style>
