<template>
  <div>
    <contents :content-data="contentData" :method-path="methodPath" />
  </div>
</template>

<script>
import contents from '@/components/contentComponent/content.vue'
import textClass from '../common.js'
export default {
  components: { contents },
  data() {
    return {
      contentData: [
        { id: 'name', label: '', style: { color: '#333333', fontSize: '18px' }, class: ['oneLine'] },
        { id: 'company', label: '申请单位' },
        { id: 'type', label: '申请类型' },
        { id: 'strStatus', label: '申请状态', type: 'status', textClass: (val, routeName) => textClass(val, routeName) }, // routeName 路由最后面那一截名字 /xxx 里的xxx
        { id: 'createTime', label: '申请时间', style: { 'margin-top': '4px' }}
      ],
      methodPath: { // 接口必传
        search: 'personal/DemandGetDemandList' // 查找接口

      }
      // routeName: this.$route.path.split('/').slice(-1)[0] // 默认取路由最后面那一截 /personal/Demand
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
