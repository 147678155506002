<template>
  <div v-loading="loading" class="Content">
    <div v-if="dataInfo.length" class="content">
      <!-- 1文章、2视频、3公告、4三务公开、5精准扶贫  145一类 -->
      <div>
        <div v-for="(item,index) in dataInfo" :key="index" class="content-item">
          <div class="content-text">
            <div
              v-for="(item2,index2) in contentData"
              :key="index2"
              :class="item2.class || []"
              :style="item2.style || {}"
              class="colcenter"
            >{{ item2.label?item2.label + '：':'' }}<span :class="classCheck(item,item2)">{{ item[item2.id] }}</span></div>
          </div>
          <div class="right" style="width:max-content">
            <!-- <div class="del center click" style="margin-right:32px" @click="$showNotice(()=>deleteItem(item.id))">删除</div> -->
            <div class="detail center click" @click="goUrl(item.id)">详情</div>
          </div>
          <!-- <div v-else class="right" style="width:304px">
            <div class="del center click" :style="{'margin-right':'32px',display:statusData[routeName][item.strStatus].del || ''}" @click="$showNotice(()=>deleteItem(item.id))">删除</div>
            <div class="detail center click" :style="{'margin-right':'32px',display:statusData[routeName][item.strStatus].cancel || ''}" @click="cancelsMethod(item.id)">取消</div>
            <div class="detail center click" @click="goUrl(item.id)">详情</div>
          </div> -->
        </div>
      </div>
      <div class="center">
        <el-pagination
          v-if="total>0"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="limit"
          class="pagination"
          :current-page="page*1"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <div
      v-else-if="!loading"
      style="flex: 1; font-size: 100px; height: 500px"
      class="colCenter it"
    >
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
import { statusData } from '@/views/personal/page/common.js'
export default {
  props: {
    contentData: {
      type: Array,
      default: () => [

      ]
    },
    methodPath: {
      type: Object,
      default: () => { return {} }
    },
    limit: {
      type: Number,
      default: 4
    },
    detailUrl: {
      type: String,
      default: ''
    },
    initQuery: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      page: 1, // 当前页数
      dataInfo: [],
      total: 0,
      loading: true,
      statusData,
      routeName: this.$route.path.split('/').slice(-1)[0]
    }
  },
  computed: {
    type() {
      return this.$parent.param.type
    }
  },
  watch: {
    $route() {
      this.getDataInfo()
    }
  },
  created() {
    this.getDataInfo()
  },

  methods: {
    // cancelsMethod(id) {
    //   this.$store.dispatch(this.methodPath.cancel, { id }).then(res => {
    //     if (res.code === 0) { // 删除成功
    //       this.$changeParam({ page: this.page })
    //       this.$message.success('取消成功')
    //     }
    //   })
    // },
    goUrl(id) {
      this.$router.push({ path: `/personal/${this.routeName}/detail`, query: { id, selected: this.$parent.$parent.$refs.left.selected, page: this.page }})
    },
    classCheck(item, item2) {
      if (item2.textClass) {
        return item2.textClass(item[item2.id], this.routeName)
      } else {
        return []
      }
    },
    handleCurrentChange(val) {
      this.$changeParam({ page: val })
    },
    getDataInfo(page, limit = this.limit) {
      this.loading = true
      this.param = this.$getParams()
      page = this.param.page || 1
      this.page = page
      this.$store.dispatch(this.methodPath.search, { page, limit, ...this.initQuery }).then(res => {
        if (res.code === 0) {
          this.total = res.count
          this.dataInfo = res.data
          this.loading = false
        }
      })
    }
    // deleteItem(id) {
    //   this.$store.dispatch(this.methodPath.del, { id }).then(res => {
    //     if (res.code === 0) { // 删除成功
    //       if (this.dataInfo.length === 1 && this.page > 1) { // 表示删了最后一条数据,且不是第一页
    //         this.$changeParam({ page: this.page - 1 })
    //       } else {
    //         this.$changeParam({ page: this.page })
    //       }
    //       this.$message.success('删除成功')
    //     }
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.Content {
  width: 100%;
  overflow: hidden;
  min-height: 600px;
  .title {
    border-bottom: 1px solid #e0e0e0;
    height: 41px;
    .text {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-bottom: 4px solid var(--theme);
      height: 41px;
      display: inline-block;
    }
  }
  .content {

    .content-item {
      display: flex;
      background: #F6F8FA;
      padding: 30px;
      margin-top: 20px;
      .right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .del,.detail{
          width: 80px;
          height: 32px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
        }
        .del{
          background: #F6F8FA;
          border: 1px solid #E0E0E0;
          border-radius: 4px;
          color: #999999;
        }
        .detail{
          background: #F6F8FA;
          border: 1px solid  var(--theme);
          border-radius: 4px;
          color: var(--theme);
        }
      }
      .content-text {
        flex: 1;
        overflow: hidden;
        div{
          line-height: 32px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          color: #666666;
        }
      }
    }
    .content-item:first-child{
      margin-top: 27px;
    }
  }
  .pagination {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .colcenter{
    display: flex;
    align-items: center;
  }

}

</style>
